.modal-left {
  .modal-dialog {
    position: fixed;
    left: 0;
    margin: 0;
    height: 100%;
    box-shadow: none;
    transform: translateX(-100%) !important;

    .modal-content {
      height: 100%;
      min-width: 350px;
      max-width: 400px;

    }
  }
}

.modal.show .modal-dialog {
  transform: translateX(0) !important;
  transition: transform .5s ease-out;
}

.custom-accordion-left-menu {

  .accordion-item {
    background-color: transparent;
    border: none;
    margin: 10px 0;

    .mm-active{
      button{
         color: $sidebar-dark-menu-item-active-color !important;
         margin-left: 5px;
      }
    }

    .accordion-header {
      position: relative;
      transition: 0.2s;

      button {
        padding: 0.6em 1.5em;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        // color: var(--color-secondary);
        color: $sidebar-dark-menu-item-color;
        box-shadow: none;
        font-size: 13px;
        display: flex;
        align-items: center;

        i {
          font-size: 22px;
          margin-right: 10px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        &::after {
          // background-image: url(./../../public/font/img/icon/down.svg);
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
      }


      &:hover {
        button {
          color: $sidebar-dark-menu-item-active-color;
        }

        margin-left: 5px;
      }

      &.active-content {

        button {
          color: $sidebar-dark-menu-item-active-color;
        }


        &::after {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $sidebar-dark-menu-item-color;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          line-height: 0;
        }

        margin-left: 5px;
      }

    }

    .accordion-body {
      padding-top: 5px;
      padding-left: 5px;

      a {
        font-size: 18px;
        font-weight: 500;
        margin: 7px 0;
        color: $sidebar-dark-menu-item-color;
        transition: .3s;

        &:hover {
          margin-left: 5px;
          color: $sidebar-dark-menu-item-active-color;
        }
      }
    }
  }

  .active-left-menu-item {
    // color: var(--color-primary);
    color: $sidebar-dark-menu-item-color;
    margin-left: 5px;
  }

  .without-arrow {
    button {
      &::after {
        display: none !important;
      }
    }
  }

  .accordion-collapse {
    margin-left: 15px;
  }
}

.vertical-menu{

  &::-webkit-scrollbar {
    width: 3px
  }
  
  &::-webkit-scrollbar-track {
    background: lighten($sidebar-dark-bg, 50);
    border-radius: 5px
  }
  
  &::-webkit-scrollbar-thumb {
    background: lighten($sidebar-dark-bg, 10);
    border-radius: 0px
  }
}

.cryllex-select{
  z-index: 13 !important;
}