.action-block {
  text-align: center;
}
.error-border {
  border: 1px solid red;
}
.red-color {
  color: red;
}
.editorClassName {
  background-color: #ffffff;
}
.file-library-table img {
  max-width: 100px;
  height: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
.font-weight-500 {
  font-weight: 500;
}
.disabled {
  pointer-events: none;
  opacity: 0.8;
}
.dashboard-spinner-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  top: 0;
  z-index: 3;
  opacity: 0.8;
}
.dashboard-spinner-layer .sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.cx-alert-container {
  position: fixed;
  right: 10px;
  top: 80px;
  z-index: 1100;
}
.cx-alert-container .cx-alert-block {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  padding: 15px 5px;
  border-radius: 10px;
  z-index: 49;
  background-color: #252526;
  max-width: 500px;
  width: 100%;
  min-width: 300px;
  margin-bottom: 10px;
}
.cx-alert-container .cx-alert-block .alert-block-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.cx-alert-container .cx-alert-block .alert-block-wrapper .alertText {
  color: #ffffff;
  margin: 0 15px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cx-alert-container .cx-alert-block .alert-block-wrapper img {
  width: 25px;
  opacity: 0.9;
}
.cx-alert-container .cx-alert-block .alert-block-wrapper .close-icon {
  margin-left: auto;
  width: 20px !important;
  cursor: pointer;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.remove-file-icon {
  margin-right: 10px;
}
.remove-file-icon i {
  color: red;
  font-size: 25px;
  transition: 0.2s;
  cursor: pointer;
}
.remove-file-icon i:hover {
  transform: scale(1.2);
  transition: 0.2s;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.42) !important;
  z-index: 1160 !important;
}
.text-blur {
  color: transparent;
  text-shadow: 0 0 4px #000000;
}
.custom-ui {
  padding: 20px 40px;
  background-color: #ffffff;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: center;
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
}
.custom-ui p {
  margin-bottom: 12px;
}
.custom-ui a {
  margin: 5px;
}
.custom-ui .yes,
.custom-ui .no {
  padding: 10px 25px;
}
.all-images {
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;
  overflow-y: auto;
}
.all-images .current-image {
  position: relative;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid #b3b3b3;
  background-position: center;
  background-size: contain;
  margin: 2px;
  flex: 1 19%;
  max-width: 150px;
}
.all-images i {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #fff;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 4px 0;
  transition: 0.2s;
}
.all-images i:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
.all-images span {
  position: absolute;
  top: 10px;
  right: 10px;
  color: green;
}
.payment-pachage-form .dropdown-toggle {
  width: 300px;
  text-align: left;
  position: relative;
  background-color: #ffffff !important;
  border-color: #CED4DA;
  color: #000000;
}
.payment-pachage-form .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.payment-pachage-form .dropdown-menu {
  max-height: 300px;
  width: 300px;
  overflow-y: auto;
}
.back-arrow-block i {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.2s;
}
.back-arrow-block i:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
.navbar-light {
  overflow: hidden;
}
.navbar-light::-webkit-scrollbar {
  display: none;
}
.horizontal-scrollbar {
  overflow-x: auto;
}
.horizontal-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.horizontal-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #C1C1C1;
  border-radius: 10px;
}
.left-menu button {
  background-color: transparent;
}
.table_link {
  color: inherit;
}
.table_link:hover {
  text-decoration: underline;
}
