.resize-none {
  resize: none;
}

.underline {
  text-decoration: underline !important;
}

.w_10 {
  width: 10px;
}

.w_20 {
  width: 20px;
}

.w_30 {
  width: 30px;
}

.w_40 {
  width: 40px;
}

.w_50 {
  width: 50px;
}

.w_60 {
  width: 50px;
}

.w_70 {
  width: 70px;
}

.w_80 {
  width: 80px;
}

.w_90 {
  width: 90px;
}

.w_100 {
  width: 100px;
}

.word-break-break-word {
  word-break: break-all;
}

.capitailze {
  text-transform: capitalize;
}

.absolute-center-horizontal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-vertical {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.word-break-break-word {
  word-break: break-word;
}

.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.max-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.max-line-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.border-none {
  border: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.course-media-file-container {
  width: 300px;
  height: 200px;
  border: 1px solid #EDEEF3;
  background-repeat: no-repeat;
  background-position: center;
}

.bold {
  font-weight: bold;
}

.small-rich-text-editor {
  .cke_contents {
    height: 100px !important;
  }
}

.custom-rich-text-editor {

  .cke_bottom {
    display: none;
  }
}

.spinner-layer {
  width: calc(100vw - #{$sidebar-width});
  height: 100%;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: $sidebar-width;
  opacity: 0.8;
  z-index: 1;

  .sweet-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 991.98px) {
    left: 0;
    width: 100%;
  }
}

.progress {
  height: 10px !important;
}

.fw-500 {
  font-weight: 500;
}

.more-less-text {
  span {
    max-width: 100% !important;
  }
}

.cke_notifications_area{
  display: none !important;
}